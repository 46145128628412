/**
 * Theme for Virgin AU site
 */
import type { Theme } from '@emotion/react';
import { buildTheme, globals } from 'common-ui';
import merge from 'lodash/merge';
import { icons } from 'common-font-icons';
import type { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types.d';
import { buildThemeMixinsAPACLW, buildThemeWidgetStylesAPACLW, buildThemeVarsAPACLW } from 'dw-apac-law/theme';
import { cmsTBSStyles } from 'common-ui/src/utils/themeHelper';

export const buildThemeVarsAPACAdc = (vars: Pick<Theme, CommonThemeVars>) => {
  //primary
  const adcOrange100 = '#FEEFED'; //color1
  const adcOrange200 = '#FBE6F1'; //color2
  const adcOrange500 = '#F15B46'; //color4
  const adcOrange700 = '#A94031'; //color5
  const adcBlue200 = '#96B3BF'; //color7
  const adcBlue300 = '#257193'; //color8
  const adcBlue400 = '#065679'; //color9
  const adcBlue500 = '#004563'; //color10
  const adcOceanGreen = '#009F8E';
  const adcBlue700 = '#370A4C'; //color11
  const adcPinkOfferPill = '#F15B46';
  const highlightMenu = '#ef5f40';

  //secondary
  const adcCream300 = '#F8F5EF'; //color12
  const adcCream400 = '#EEEAE2'; //color13
  const adcCream500 = '#F4F3F0';
  const adcCream600 = '#CFC6BE'; //color14
  const adcCream700 = '#A49384'; //color15
  const adcWhite = '#FFFFFF'; //color16
  const adcGrey100 = '#F8F8F8'; //color17
  const adcGrey200 = '#EDEDED'; //color18
  const adcGrey400 = '#BCBCBC'; //color19
  const adcGrey600 = '#767676'; //color20
  const adcGrey700 = '#616161'; //color21
  const adcGrey800 = '#494949'; //color22
  const adcBlack = '#222222'; //color23
  const adcSvgCheckboxBg = '%23A94031';

  //accent - Laithwaites Palette
  const adcMaroon500 = '#7A0040'; //color24
  const adcNavy400 = '#203C5D'; //color25
  const adcPink500 = '#E2004D'; //color26
  //accent-Green
  const adcMoss = '#ABC7AB'; //color27
  const adcTeal = '#469D8E'; //color28
  //accent-Gold Gradient
  const adcGoldGradientLight = '#FFE298'; //color29
  const adcGoldGradientDark = '#A48237'; //color30
  const lwPink = '#CF004F';
  const oceanGreen = '#00797F';

  const lwThemeVars = buildThemeVarsAPACLW(vars);

  return merge({}, lwThemeVars, {
    meta: {
      name: 'Virgin AU',
    },
    icons,
    assets: {
      headerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
      headerLogoMobileImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
      footerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo-white.svg`,
    },
    colors: {
      primary1: adcNavy400,
      primary2: adcBlue400,
      primary3: adcCream500,
      primary4: adcOrange100,
      primary5: adcOrange200,
      primary6: lwPink,
      primary7: adcOrange700,
      primary8: adcBlack,
      primary9: adcBlue200,
      primary10: adcBlue300,
      primary11: adcBlue400,
      primary12: adcBlue500,
      primary13: adcBlue700,
      primary14: adcOceanGreen,
      secondary1: adcCream300,
      secondary2: adcCream400,
      secondary3: adcCream600,
      secondary4: adcCream700,
      secondary5: adcWhite,
      secondary6: adcGrey100,
      secondary7: adcGrey200,
      secondary8: adcGrey400,
      secondary9: adcGrey600,
      secondary10: adcGrey700,
      secondary11: adcGrey800,
      secondary12: adcBlack,
      accentMaroon500: adcMaroon500,
      accentNavy400: adcNavy400,
      accentPink500: adcPink500,
      accentMoss: adcMoss,
      accentTeal: adcTeal,
      accentGoldGradientLight: adcGoldGradientLight,
      accentGoldGradientDark: adcGoldGradientDark,
      textPrimaryBg: adcOrange500,
      oceanGreen,
      svgCheckboxBg: adcSvgCheckboxBg,
      savingsTextColor: adcOrange500,

      //specific colour definitions
      btnsPrimaryBg: adcNavy400,
      btnsPrimaryColor: vars.colors.white,
      btnsPrimaryHoverBg: adcOrange500,
      btnsPrimaryHoverColor: vars.colors.white,
      btnsSecondaryBg: vars.colors.white,
      btnsSecondaryColor: vars.colors.black,
      btnsSecondaryHoverBg: adcOrange700,
      btnsSecondaryHoverColor: vars.colors.white,
      btnsTertiaryBg: vars.colors.white,
      btnsTertiaryColor: vars.colors.black,
      btnsTertiaryBorder: vars.colors.white,
      btnsTertiaryHoverBg: vars.colors.white,
      btnsTertiaryHoverColor: vars.colors.black,
      pinkOfferPillBg: adcPinkOfferPill,

      footerPrimaryBg: adcBlue500,
      footerdrinkaware: vars.colors.white,

      highlightMenu,

      bgColors: {
        // adcOrange: adcOrange500,
        adcOrange: adcNavy400,
        adcBlue: adcBlue400,
        adcCream: adcCream400,
        adcWhite,
        adcBlack,
      },
    },
    fonts: {
      familyPrimary: lwThemeVars.fonts.oswald,
      familySecondary: lwThemeVars.fonts.roboto,
      familyTertiary: lwThemeVars.fonts.noto,
      headerMainNavTitle: lwThemeVars.fonts.noto,
      headerMainNavSubTitle: lwThemeVars.fonts.roboto,
      weightLight: '300',
      weightNormal: '400',
      weightMedium: '500',
      weightMediumPlus: '600',
      weightBold: '700',
    },
  });
};

export const buildThemeMixinsAPACAdc = (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
  const buildThemeWidget = buildThemeMixinsAPACLW(vars);

  return merge({}, buildThemeWidget, {
    hxl: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize45};
      font-weight: ${vars.fonts.weightMedium};
      line-height: ${vars.fonts.fontLineHeight100};
      letter-spacing: ${vars.fonts.fontLetterSpacing1};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize64};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight100};
      }
    `,
    h1: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize34};
      font-weight: ${vars.fonts.weightMedium};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing2};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize48};
        letter-spacing: ${vars.fonts.fontLetterSpacing2};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight105};
      }
    `,
    h2: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize30};
      font-weight: ${vars.fonts.weightMedium};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing2};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize34};
        letter-spacing: ${vars.fonts.fontLetterSpacing2};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight105};
      }
    `,
    h3: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize28};
      font-weight: ${vars.fonts.weightMedium};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing10};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize30};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight110};
      }
    `,
    h4: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize26};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing4};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize30};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    h5: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize24};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing4};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize26};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    h6: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize20};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing4};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize22};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    h7: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing6};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize18};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    h8: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing6};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize18};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    eyebrowRegular: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize14};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    eyebrowSmall: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize10};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    utility: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize10};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
        line-height: ${vars.fonts.fontLineHeight115};
        letter-spacing: ${vars.fonts.fontLetterSpacing8};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    quote: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize20};
      font-style: ${vars.fonts.styleItalic};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing1};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize28};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    body1: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    body2: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize14};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    bodySmall: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    caption: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
  });
};

export const buildThemeWidgetStylesAPACAdc = (vars: Omit<Theme, 'widgets'>) => {
  const buildThemeWidget = buildThemeWidgetStylesAPACLW(vars);

  return merge({}, buildThemeWidget, {
    GlobalHeader: {
      headerLogo: `
      img {
         max-width: 13em;
      }`,
      mainNavBarWrapper: `
        ${buildThemeWidget.GlobalHeader.mainNavBarWrapper}
        .megaNavTab {
          font-size: 18px;
          text-underline-offset: ${vars.space.lg};
          padding: ${vars.space.lg} 15px;
          display: inline-block;
          font-family: ${vars.fonts.headerMainNavTitle};
          color: ${vars.colors.grey50};
          letter-spacing: ${vars.fonts.fontLetterSpacing6};
          font-weight: 600;
          ${vars.breakpoints.xl} {
            padding: ${vars.space.lg} 20px;
          }

          &:hover {
            text-decoation: none;
          }

          &.emphasize, &.emphasize span {
            color: ${vars.colors.primary1};
            font-weight: ${vars.fonts.weightBold};
          }
        }
      `,
      searchNavBar: `
        ${buildThemeWidget.GlobalHeader.searchNavBar}
        .search-navbar-container {
          .navbar-right-container {
            & > div {
              gap: 0;
            }
          }
          .navbar-center-container {
            .sticky-nav-bar {
              #header-logo {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }`,
    },
    Search: {
      hitWrapper: ` 
      ${buildThemeWidget.Search.hitWrapper}
      &.grid-view {
        .ais-Hits-list {
          .ais-Hits-item {
            .hit-content {
              .title {
                font-family: ${vars.fonts.familyTertiary};
              }
            }
            .addToCart-container { 
              .price-section {
                .sku-price {
                  font-weight: ${vars.fonts.weightSemiBold};
                  font-family: ${vars.fonts.familyTertiary};
                  .original-price-bottle { 
                    font-family: ${vars.fonts.familyTertiary};
                  }
                  .vpp-price-section {
                    font-family: ${vars.fonts.familySecondary};
                    .sales-price {
                      color: ${vars.colors.textPrimaryBg}
                    }
                  }
                }
              }
              .add-to-cart-btn-container {
                .top-price-section {
                  .price-section {
                    .pricing-panel-container {
                      .straight-sku-container {
                        .vpp-price {
                          color: ${vars.colors.textPrimaryBg};
                        }
                      }
                      .vpp-btn {
                        .member-price-section {
                          margin-top: 10px;
                        }
                      }
                    }
                  }
                }
                button {
                  &:hover {
                    background-color: ${vars.colors.btnsPrimaryHoverBg}
                  }
                }
              }
            }
          }
        }
      }
      `,
    },
    Product: {
      orderform: `
      ${buildThemeWidget.Product.orderform}
      .straight-sku-container{
        .vpp-price {
            color: ${vars.colors.textPrimaryBg};
          }
        }
      `,
    },
    Footer: {
      default: `
      ${buildThemeWidget.Footer.default}
      font-family: ${vars.fonts.familySecondary};
      p {
        font-family: ${vars.fonts.familySecondary};
      }
      @media print {
        display: none;
      }
      .footer-bottom-section {
        background: ${vars.colors.footerPrimaryBg};
        box-shadow: 0 0 0 100vmax ${vars.colors.footerPrimaryBg};
        clip-path: inset(0 -100vmax);
        color: ${vars.colors.white};
        .wrapper {
          .copyrights {
            p {
              color: ${vars.colors.white};
              a {
                color: ${vars.colors.white};
              }
            }
          }
          .brand img {
              max-width: 13em;
          }
          ${vars.breakpoints.lg} {
            .bedrinkaware-group {
              justify-content: flex-start;
            }
          }
        }
      }
      .footer-nav-links {
        background: ${vars.colors.secondary1};
        box-shadow: 0 0 0 100vmax ${vars.colors.secondary1};
      }
      .social-connections {
        .wrapper {
          .social-connection-heading p {
            font-family: ${vars.fonts.familySecondary};
            letter-spacing: 2px;
          }
        }
      }
      `,
      navItems: `
        ${buildThemeWidget.Footer.navItems}
        > div:first-of-type p{
          text-transform: uppercase;
          font-weight: bold;
        }
  `,
    },
    Cart: {
      MiniCart: `
      ${buildThemeWidget.Cart.MiniCart}
      .price-section .strike+.sale-price {
        color: ${vars.colors.textPrimaryBg}
      }
      .price-section .strike+.member-price {
        color:${vars.colors.primary14};
      }
      .member-savings {
        color: ${vars.colors.primary14};
      }
     
    `,
    },
    CmsTBSPage: cmsTBSStyles(vars),
    Membership: {
      pageWrapper: `
      ${buildThemeWidget.Membership.pageWrapper}
      & .sidebar{
        display: none;
        ${vars.breakpoints.md} {
           display:flex;
        }
        .grid-container div .navigation-link a.active {
          color: ${vars.colors.highlightMenu};
        }
      }
      & .top-section{
          .dynamicComponents{
            >.widget-container{
             .cms-image-container{
                background: ${vars.colors.whiteWine};
                display:flex;
                justify-content:center;
                img{
                  padding:10px;
                  width:300px;
                }
              }
            }
            >.widget-cms-column{
              & .grid-container {
                    justify-content: space-between;
                    & .cms-image-container img{
                      width:88px;

                    }

              }
            }
          }
        }
        & .bottom-section{
          & .accordion-item{
             padding: 15px 0px;
            .accordion-title h3{
                font-size: ${vars.fonts.fontSize18};
            }
            .accordion-content h8{
              font-weight: normal;

            }
          }
        }
        & .cancel-link-monthly-notes{
            ${vars.mixins.body1};
             background-color: ${vars.colors.secondaryLight};
             padding: 10px;
             margin-bottom:5px;
             i{
               margin-right:5px;
             }

        }
      `,
    },
    MembershipRebranding: {
      membershipStatus: `
      ${buildThemeWidget.MembershipRebranding.membershipStatus}
        border: 1px solid ${vars.colors.lwGrey400};
        padding: 24px;
        .re-join-note{
           margin-top: 12px;
        }
        .year-note {
          font-weight: ${vars.fonts.weightBold};
          font-family: ${vars.fonts.roboto};
          color: ${vars.colors.unltPopupBackground};
       
        }
        .add-unlimited-to-cart button{
           margin-top: 20px;
          background: ${vars.colors.unltPopupBackground};
          :hover{
           background-color: ${(vars.colors.bgColors as unknown as { [key: string]: string }).LWDarkBlue};
           color: ${vars.colors.white};
            
          }
        }
        .saving-instruction {
          font-family: ${vars.fonts.roboto};
          padding-top: 10px;
          font-weight: ${vars.fonts.weightNormal};
          font-size: ${vars.fonts.sizeLarge};
        }
        .saving-note {
          font-family: ${vars.fonts.roboto};
          font-weight: ${vars.fonts.weightNormal};
          font-size: ${vars.fonts.fontSize14};
          color: ${vars.colors.grey60};
          margin-top: 12px;
        }
        .price-section {
          display: flex;
          align-items: baseline;
        }
        .membership-status-title {
          font-family: ${vars.fonts.noto};
          font-weight: ${vars.fonts.weightMediumPlus};
        }
        .membership-subscription-title {
          font-family: ${vars.fonts.roboto};
          font-weight: 700;
          font-size: ${vars.fonts.sizeLarge};
          padding-top: 10px;
          .monthly-notes{
            ${vars.mixins.body2};
            i{
              margin:0px 10px;
            }
          }
        }
        .enroll-info {
          margin-top: 12px;
          font-family: ${vars.fonts.roboto};
          font-weight: ${vars.fonts.weightBold};
          font-size: ${vars.fonts.sizeLarge};
          & .date {
            font-weight: ${vars.fonts.weightNormal};
            font-size: ${vars.fonts.sizeLarge};
            padding-right: 8px;
          }
        }
      `,
      layout: `
         ${buildThemeWidget.MembershipRebranding.layout}
         & .change-payment-card a{
           font-family: ${vars.fonts.roboto};
           text-decoration: underline;
           font-size: ${vars.fonts.fontSize14};
           }
       `,
    },
    MyAccount: {
      unlimitedModalWrapper: `
       ${buildThemeWidget.MyAccount.unlimitedModalWrapper}
        & .mantine-Modal-title{
          font-weight: ${vars.fonts.weightMedium};
        }
       .unlimited-modal-content-wrapper{
          gap:20px;
         
          .mantine-Text-root{
              font-family: ${vars.fonts.roboto};
              font-size: ${vars.fonts.fontSize16};
              font-weight: ${vars.fonts.weightNormal};
          }
          .swap-note{
             ${vars.mixins.body1};
            
          }
          .heading-notes{
            font-size: ${vars.fonts.fontSize16};
            }
          }
         .unlimited-icons{
           i {
            color:${vars.colors.primary14} !important;
            font-size: 50px;
            background: ${vars.colors.whiteWine};
            border-radius: 100%;
           }
           .notes .notes-heading{
             ${vars.mixins.eyebrowRegular};
             ${vars.breakpoints.sm} 
             {
              font-size: ${vars.fonts.fontSize12};
             }

            }
         }
         button.unlimited-modal-tertiary-cta {
          font-weight: ${vars.fonts.weightSemiBold};
         }
         & .monthly-offer-box{
          .radio-offer{
             border: 1px solid ${vars.colors.lwGrey400};
             margin:15px;
             padding:10px;
            &.active{
               border: 1.5px solid ${vars.colors.lwBodyBlack};
            }
            & input:checked:not(:disabled) {
                background-color: transparent;
                border: 2px solid ${vars.colors.btnsPrimaryBg};
                ~ svg{
                  color: ${vars.colors.btnsPrimaryBg};
                }
              }   
          
            label{
                ${vars.mixins.body1};
                 font-weight: ${vars.fonts.weightBold};
            }
            .badge-row {
              display: flex;
               justify-content: space-between;
              .mantine-Badge-root{
                ${vars.mixins.body3};
                    font-weight: ${vars.fonts.weightMediumPlus};
                background-color: ${vars.colors.positiveDark};
                color:${vars.colors.white};
                text-transform: capitalize;
                padding:10px;
               }
              }
              .offer-notes{
                margin-left: 5%;
                ${vars.mixins.body2};
                margin-top: 12px;
              }
          }
         }
    `,
    },
  });
};

const createTheme: ThemeCreateFunction = () =>
  buildTheme(buildThemeVarsAPACAdc, buildThemeMixinsAPACAdc, buildThemeWidgetStylesAPACAdc);

export default createTheme;
