import type { Theme } from '@emotion/react';
export const cmsTBSStyles = (vars: Omit<Theme, 'widgets'>) => ({
  sideBarLegacy: `
    & .sidebar {
      & .grid-container {
        div {
          width: 100%;
          .navigation-link {
            h4 {
              display: none; 
            }
            & a {
              margin-left: 10px;
              margin-bottom: 12px;
              display: flex;
              align-items: center;
              gap: 10px;
              font-family: ${vars.fonts.familySecondary};
              font-size: ${vars.fonts.fontSize14};
              font-weight: ${vars.fonts.weightNormal};
              line-height: 22.4px;
              letter-spacing: 0.04em;
              color: ${vars.colors.grey50};
              &:last-child {
                & i {
                  font-size: unset;
                }
              }
            }
          }
        }
      }
      & .cms-conditional-container {
        margin-left: -24px;
        & .grid-container > div {
          display: flex;
          align-items: center;
          padding-bottom: 5px;
          line-height: 25.2px;
          letter-spacing: 0.04em;
          gap: 10px;
          margin-bottom: 10px;
          .cms-image-container {
            display: flex;
            width: fit-content;
            img {
              width: 25px;
            }
          }
          a {
            font-family: ${vars.fonts.oswald};
            font-size: ${vars.fonts.fontSize16};
            color: ${vars.colors.grey50};
            margin: unset;
          }
        }
      }
    }
  `,
  breadcrumbCSS: `
    padding: 12px 24px 12px 0px;
    & a {
      display: flex;
      align-items: center;
      gap: 8px;
      &:hover, &:active, &:focus {
        text-decoration: none;
      }
      & .link-text {
        text-decoration: none;
        border-bottom: 1px solid ${vars.colors.primary8};
        font-size: ${vars.fonts.sizeH5};
        font-weight: bold;
      }
    }
  `,
});
